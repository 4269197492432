import React from 'react';

/*
function Species(props){  
  //use Origin + Species to display description
  const lotOrigin    = props.lotOrigin[0];
  const lotSpecies   = props.lotSpecies;
  let innerTitle     = "speciesPlaceholder"; 
  
  if(lotSpecies.includes('Goose')){
    innerTitle = "Anser Anser f. Domestica"
  }else{
    //duck
    innerTitle = "Anas Bochas Domestica"
  }
  

  return <div className="content-ac sp">
		 <input
		   className="ac-input top"
		   id="ac-1afd"
		   name="ac-1afd"
		   data-id="2400"
		   data-slug="SPECIES"
		   type="checkbox"
		 />
		 <label className="ac-label" htmlFor="ac-1afd">SPECIES</label>
		 <article className="ac-text ac-1afd">
		   <div className="single-post-interior">
		     <p className="data">{ innerTitle }<br/>{ lotSpecies }</p>
		     <p>Down comes as a by-product of the food industry and almost all of these domesticated geese are descended from the Greylag Goose. Within this family, there are a number of different subspecies which all exhibit the same characteristics in down and only really differ in color. When it comes to down quality, the Goose is a larger bird than duck and because of this, grows larger down clusters. The goose also has a different composition of fat and oil which leads to slightly better resiliency and durability than its duck counterpart.</p>		     
		   </div>
		 </article>
		</div>		
}*/

function GooseDesc(){
  return(<><p>Down comes as a by-product of the food industry and almost all of these domesticated geese are descended from the Greylag Goose. Within this family, there are a number of different subspecies which all exhibit the same characteristics in down and only really differ in color. When it comes to down quality, the Goose is a larger bird than duck and because of this, grows larger down clusters. The goose also has a different composition of fat and oil which leads to slightly better resiliency and durability than its duck counterpart.</p></>);	
}

function DuckDesc(){
  return(<><p>Down becomes available as a by-product of the food industry and almost all of these domesticated ducks are descended from the Mallard. Within this family, there are a number of different sub-species which all exhibit the same characteristics in their down and primarily differ only in color. Ducks tend to be younger when processed so generally the down clusters that they produce are smaller and more compact. However, we have recently uncovered supply chains in extremely remote areas with farms and collectors that can now provide fill powers up to 850, rivaling most Goose Down insulations.</p></>)
}

function RenuDesc(){
	return(<><p>ALLIED’s entire supply chain is audited by SCS Global Services and Control Union to certify RENU under the Recycled Content Certification and Global Recycled Standard for product&nbsp;labeling.</p></>)
}

function RenuDescFR(){
	return(<><p>Toute la chaîne d&#39;approvisionnement d&#39;ALLIED est auditée par SCS Global Services et Control Union pour certifier RENU dans le cadre de la certification du contenu recyclé et de la norme mondiale de recyclage pour l&#39;étiquetage des&nbsp;produits.</p></>)
}


function GooseDescFR(){
  return(<><p>Le duvet est un sous-produit de l&#39;industrie alimentaire et presque toutes ces oies domestiquées descendent de la Greylag Goose. Au sein de cette famille, il existe un certain nombre de sous-espèces différentes qui présentent toutes les mêmes caractéristiques en matière de duvet et ne diffèrent vraiment que par la couleur. En ce qui concerne la qualité du duvet, l&#39;oie est un oiseau plus grand que le canard et, pour cette raison, elle pousse des grappes de duvet plus grandes. L&#39;oie a également une composition différente de graisse et d&#39;huile, ce qui lui confère une résistance et une durabilité légèrement supérieures à celles de son homologue&nbsp;canard.</p></>);	
}

function DuckDescFR(){
  return(<><p>Le duvet devient un sous-produit de l&#39;industrie alimentaire et presque tous ces canards domestiqués descendent du canard colvert. Au sein de cette famille, il existe un certain nombre de sous-espèces différentes qui présentent toutes les mêmes caractéristiques dans leur duvet et ne diffèrent principalement que par la couleur. Les canards ont tendance à être plus jeunes au moment de leur transformation, de sorte que les grappes de duvet qu&#39;ils produisent sont généralement plus petites et plus compactes. Cependant, nous avons récemment découvert des chaînes d&#39;approvisionnement dans des régions extrêmement éloignées avec des fermes et des collecteurs qui peuvent maintenant fournir des pouvoirs de remplissage allant jusqu&#39;à 850, rivalisant avec la plupart des isolants en duvet d&#39;oie.</p></>)
}


function MuscDuckDesc(){
 return(<><p>tktktk</p></>)
}

function MuscDuckDescFR(){
 return(<><p>FRENCH !tktktk</p></>)
}


function SpecificSpecies(props){    
  switch(props.activeLang){
    case "fr":	    	    
	  if(props.s.includes('RENU')){	
        return(<RenuDescFR />)
	  }if(props.s.includes('Muscovy')){	
		  return(<MuscDuckDescFR />)
	  }else if(props.s.includes('Goose')){
        return(<GooseDescFR />)  	  
	  }else{
        return(<DuckDescFR />)  	  
	}
	break;
	
	default:
	  if(props.s.includes('RENU')){	
      return(<RenuDesc />)
	  }if(props.s.includes('Muscovy')){	
	  return(<MuscDuckDesc />)
	  }else if(props.s.includes('Goose')){
      return(<GooseDesc />)  	  
	  }else{
      return(<DuckDesc />)  	  
	}
	break;
  }	  
  
}


function Species(props){  
  //use Origin + Species to display description  
  const lotOrigin    = props.lotOrigin[0];
  let cleanSpecies, cleanRENU; 
  
  let lotSpecies   = props.lotSpecies;
  if(lotSpecies == "Renu Blend"){
	switch(props.activeLang){
    case "fr":
    lotSpecies = "RENU RECYCLED DOWN";
    cleanRENU  = "RENU RECYCLED DOWN";
    break;
    
    default:
    lotSpecies = "RENU RECYCLED DOWN"
    cleanRENU  = "RENU RECYCLED DOWN";
    break;
    }
  }
  
  let innerTitle     = "speciesPlaceholder"; 
  let innerDesc;  
  
  if(lotSpecies.includes('Muscovy')){
	innerTitle = "TKTKTK SPECIES"          
  }    	
  
  if(lotSpecies.includes('Goose')){    	
    innerTitle = "Anser Anser f. Domestica"        
  }else if(lotSpecies.includes('Duck')){
    //duck
    innerTitle = "Anas Bochas Domestica"
  }else{
	innerTitle = "100% POST CONSUMER PRODUCTS"
  }
  
  switch(props.activeLang){
    case "fr":
    //cleanSpecies = lotSpecies.toString()+"FRFR";
    if(lotSpecies.includes('Goose')){
      if(lotSpecies.includes('White')){
	    cleanSpecies = "L'oie Blanche";   
	  }else{
		//FR Grey Goose  
		cleanSpecies = "L'OIE CENDRÉ";   
	  }
    }else{
	  //FR Duck
	  if(lotSpecies.includes('White')){
	    cleanSpecies = "CANARD BLANC";     
	  }else{
	    cleanSpecies = "CANARD CENDRÉ";   	  
	  }	    
    }
    break;
    default:
    cleanSpecies = lotSpecies.toString();
    break;
  }  

  return <div className="content-ac sp hide">
		 <input
		   className="ac-input top"
		   id="ac-1afd"
		   name="ac-1afd"
		   data-id="2400"
		   data-slug="SPECIES"
		   type="checkbox"
		   onChange={ () => props.changeWord('sp clicked') }
		 />
		 <label className="ac-label" htmlFor="ac-1afd">{ lotSpecies != "RENU RECYCLED DOWN" ? cleanSpecies.toUpperCase() : cleanRENU }</label>
		 <article className="ac-text ac-1afd"><div className="single-post-interior" tabIndex="0">
		     <p className="data">{ 
			   lotSpecies != "RENU RECYCLED DOWN" 
			   ? innerTitle 
			   : null 
			 }
			 {
			   lotSpecies != "RENU RECYCLED DOWN" 
			   ? <br/>
			   : null 
			 }
			 { 
			   lotSpecies != "RENU RECYCLED DOWN" 
			   ? cleanSpecies 
			   : null 
			 }</p>		     
		     {/* species Desc */}
		     <SpecificSpecies
		       activeLang = {props.activeLang}
		       s = { lotSpecies }
		     />		     
		 </div></article>
		</div>		
}


export default Species;